import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import { AccountTypes, Kostenstelle1Types } from '@/shared/model/datevSetting';

const name = 'edit-datev-export-settings-dialog';
const logger = new Logger(name);

@Component({
  name: name,
  components: {},
})
export default class EditDatevExportSettingsDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;
  marketingYear?: string = '';
  @Prop({ default: () => {} })
  private value!: any;
  menu2 = false;
  @Watch('dialog')
  onUpdateDialog(newv: boolean) {
    this.marketingYear = this.formatDate(this.value.marketingYear);
  }
  @Watch('value.marketingYear')
  onUpdateFromDateTime(newV: any) {
    this.marketingYear = this.formatDate(this.value.marketingYear);
  }
  parseDate(str: any) {
    return DateUtils.parseDate(str);
  }

  formatDate(str: any) {
    return DateUtils.formatDate(str);
  }
  get accountTypes() {
    return [
      {
        value: AccountTypes.Haben,
        text: this.$t('account_type_haben').toString(),
      },
      {
        value: AccountTypes.Soll,
        text: this.$t('account_type_soll').toString(),
      },
    ];
  }

  get CostCentre1Types() {
    return [
      {
        value: Kostenstelle1Types.CostCenterOnly,
        text: this.$t('kostenstelle1Typ_cost_center_only').toString(),
      },
      {
        value: Kostenstelle1Types.All,
        text: this.$t('kostenstelle1Typ_all').toString(),
      },
    ];
  }

  private onDPInput(newdate: any) {
    this.value.marketingYear = newdate + '-01';
    this.menu2 = false;
  }
  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.$emit('click:update', this.value);
  }
}
