import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import EditDatevExportSettingsDialog from './edit-datev-export-settings/edit-datev-export-settings.vue';
import { AccountTypes, DatevSetting, Kostenstelle1Types } from '@/shared/model/datevSetting';
import DateUtils from '@/shared/utils/DateUtils';
import { OrganisationAllocationExportProperty } from '@/shared/model/organisationAllocationExportProperty';
import { OdataItems } from '@/shared/model/OdataItems';
import organisationAllocationProperty, {
  OrganisationAllocationProperty,
} from '@/shared/model/organisationAllocationProperty';
import draggable from 'vuedraggable';
import { AllocationSetting } from '@/shared/model/allocationSetting';
import organisationDocumentExtraStatusSetting, {
  OrganisationDocumentExtraStatusSetting,
} from '@/shared/model/organisationDocumentExtraStatusSetting';
import EditOrderBooking from './edit-order-booking/edit-order-booking.vue';
import EditOrderExport from './edit-order-export/edit-order-export.vue';
import D4ySwitch from '@/components/d4y-switch/d4y-switch.vue';

const name = 'order-and-export-view';
const logger = new Logger(name);
const datevSettingModule = namespace('datevSetting');
const organisationAllocationExportPropertyModule = namespace('organisationAllocationExportProperty');
const organisationAllocationPropertyModule = namespace('organisationAllocationProperty');
const allocationSettingModule = namespace('allocationSetting');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({
  name: name,
  components: { EditDatevExportSettingsDialog, draggable, EditOrderBooking, EditOrderExport, D4ySwitch },
})
export default class OrderAndExportView extends Vue {
  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;
  @allocationSettingModule.Action('updateAllocationSetting')
  private updateAllocationSetting!: any;
  @allocationSettingModule.Action('getAllocationSettingCurrent')
  private getAllocationSetting!: any;
  @documentExtraStatusSettingModule.Getter('getOrganisationDocumentExtraStatusSettings')
  private organisationDocumentExtraStatusSettings!: OdataItems<OrganisationDocumentExtraStatusSetting>;
  @documentExtraStatusSettingModule.Action('getOrganisationDocumentExtraStatusSettings')
  private getOrganisationDocumentExtraStatusSettings!: any;

  @datevSettingModule.Action('updateDatevSetting')
  private updateDatevSetting!: any;
  @organisationAllocationExportPropertyModule.Getter('getOrganisationAllocationExportProperties')
  private organisationAllocationExportProperties!: OdataItems<OrganisationAllocationExportProperty>;
  @organisationAllocationExportPropertyModule.Action('getOrganisationAllocationExportProperties')
  private getOrganisationAllocationExportProperties!: any;
  @organisationAllocationExportPropertyModule.Action('updateOrganisationAllocationExportProperty')
  private updateOrganisationAllocationExportProperty!: any;
  @organisationAllocationExportPropertyModule.Action('moveOrganisationAllocationExportProperty')
  private moveOrganisationAllocationExportProperty!: any;
  @organisationAllocationPropertyModule.Getter('getOrganisationAllocationProperties')
  private organisationAllocationProperties!: OdataItems<OrganisationAllocationProperty>;
  @organisationAllocationPropertyModule.Action('getOrganisationAllocationProperties')
  private getOrganisationAllocationProperties!: any;
  @organisationAllocationPropertyModule.Action('moveOrganisationAllocationProperty')
  private moveOrganisationAllocationProperty!: any;
  @organisationAllocationPropertyModule.Action('updateOrganisationAllocationProperty')
  private updateOrganisationAllocationProperty!: any;

  get getterOrganisationDocumentExtraStatusSettings() {
    const newArray = [];
    newArray.push(
      organisationDocumentExtraStatusSetting.parse({
        name: this.$t('allocation_setting_export_approval_status').toString(),
        statusValue: '-',
      })
    );

    return newArray.concat(this.organisationDocumentExtraStatusSettings.items);
  }

  onChangeAllocationSettingExportGobdRequired(newV: boolean, _oldV: boolean): void {
    this.allocationSetting.exportGobdRequired = newV;
    this.updateAllocationSetting(this.allocationSetting);
  }

  onChangeAllocationSettingExportEachOffice(newV: boolean, _oldV: boolean): void {
    this.allocationSetting.exportEachOffice = newV;
    this.updateAllocationSetting(this.allocationSetting);
  }

  onChangeAllocationSettingExportApprovalStatus() {
    this.updateAllocationSetting(this.allocationSetting);
  }
  //table DatevSetting
  @datevSettingModule.Getter('getDatevSetting')
  private datevSetting!: DatevSetting;

  onClickEditDatevExportSettings() {
    this.openDatevSettingDialog(this.datevSetting);
  }
  marketingYear = '';
  parseDate(str: any) {
    return DateUtils.parseDate(str);
  }
  formatDate(str: any) {
    return DateUtils.formatDate(str);
  }
  //#region Dialog logic
  dialogAddEdit = {
    show: false,
    model: {},
  };
  onChangeOrganisationAllocationProperty_SortOrder(payload: {
    moved: { element: OrganisationAllocationProperty; newIndex: number; oldIndex: number };
  }) {
    const newIndex =
      payload.moved.newIndex > payload.moved.oldIndex
        ? this.organisationAllocationProperties.items[payload.moved.newIndex - 1].sortOrder
        : this.organisationAllocationProperties.items[payload.moved.newIndex + 1].sortOrder;

    const oldIndex = this.organisationAllocationProperties.items[payload.moved.newIndex].sortOrder;
    this.moveOrganisationAllocationProperty({
      element: payload.moved.element,
      newIndex: newIndex,
      oldIndex: oldIndex,
    }).finally(() => {
      this.getOrganisationAllocationProperties();
    });
  }
  onChangeOrganisationAllocationExportProperty_SortOrder(payload: {
    moved: { element: OrganisationAllocationExportProperty; newIndex: number; oldIndex: number };
  }) {
    const newIndex =
      payload.moved.newIndex > payload.moved.oldIndex
        ? this.organisationAllocationExportProperties.items[payload.moved.newIndex - 1].sortOrder
        : this.organisationAllocationExportProperties.items[payload.moved.newIndex + 1].sortOrder;

    const oldIndex = this.organisationAllocationExportProperties.items[payload.moved.newIndex].sortOrder;
    this.moveOrganisationAllocationExportProperty({
      element: payload.moved.element,
      newIndex: newIndex,
      oldIndex: oldIndex,
    }).finally(() => {
      this.getOrganisationAllocationExportProperties();
    });
  }
  organisationAllocationProperties_onlyEnabled = false;
  @Watch('organisationAllocationProperties_onlyEnabled')
  onChangeOrganisationAllocationProperties_onlyEnabled(newV: string) {
    this.getOrganisationAllocationProperties();
  }
  organisationAllocationExportProperties_onlyEnabled = false;
  @Watch('organisationAllocationExportProperties_onlyEnabled')
  onChangeOrganisationAllocationExportProperties_onlyEnabled(newV: string) {
    this.getOrganisationAllocationExportProperties();
  }
  options = {};
  get dragOptions() {
    return {
      animation: 220,
      group: 'description',
      disabled: false,
      ghostClass: 'ghost',
    };
  }
  onChangeOrganisationAllocationExportProperty_Enabled(element: OrganisationAllocationExportProperty) {
    this.updateOrganisationAllocationExportProperty(element);
  }
  onChangeOrganisationAllocationProperty_Enabled(element: OrganisationAllocationProperty) {
    this.updateOrganisationAllocationProperty(element);
  }
  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  async dialogAddEditOnUpdate(item: any) {
    await this.updateDatevSetting(item).then(() => {
      this.marketingYear = this.formatDate(item.marketingYear) ?? '';
      Object.assign(this.datevSetting, item);
    });
    this.dialogAddEdit.show = false;
  }

  private async openDatevSettingDialog(status: any) {
    this.dialogAddEdit.model = { ...status };
    this.dialogAddEdit.show = true;
  }
  //#endregion
  @Watch('datevSetting.marketingYear')
  ondatevSettingmarketingYear() {
    this.marketingYear = this.formatDate(this.datevSetting.marketingYear) ?? '';
  }
  async mounted() {
    await this.getOrganisationDocumentExtraStatusSettings();
    await this.getAllocationSetting();
    await this.getOrganisationAllocationExportProperties();
    await this.getOrganisationAllocationProperties();
  }

  //#region edit Order for Booking item
  dialogAddEditOrderBooking = {
    show: false,
    model: {},
  };

  async dialogAddEditOnCloseOrderBooking(folder: any) {
    this.dialogAddEditOrderBooking.show = false;
  }

  async dialogAddEditOnUpdateOrderBooking(item: any) {
    await this.updateOrganisationAllocationProperty(item).then(() => {
      this.getOrganisationAllocationProperties();
    });
    this.dialogAddEditOrderBooking.show = false;
  }

  editOrderBooking(element: any) {
    this.dialogAddEditOrderBooking.model = { ...organisationAllocationProperty.parse(element) };
    this.dialogAddEditOrderBooking.show = true;
  }

  //#endregion
  get accountTypes() {
    return [
      {
        value: AccountTypes.Haben,
        text: this.$t('account_type_haben').toString(),
      },
      {
        value: AccountTypes.Soll,
        text: this.$t('account_type_soll').toString(),
      },
    ];
  }

  get CostCentre1Types() {
    return [
      {
        value: Kostenstelle1Types.CostCenterOnly,
        text: this.$t('kostenstelle1Typ_cost_center_only').toString(),
      },
      {
        value: Kostenstelle1Types.All,
        text: this.$t('kostenstelle1Typ_all').toString(),
      },
    ];
  }

  //#region edit Order for Export item
  dialogAddEditOrderExport = {
    show: false,
    model: {},
  };

  async dialogAddEditOnCloseOrderExport(folder: any) {
    this.dialogAddEditOrderExport.show = false;
  }

  async dialogAddEditOnUpdateOrderExport(item: any) {
    await this.updateOrganisationAllocationExportProperty(item).then(() => {
      this.getOrganisationAllocationExportProperties();
    });
    this.dialogAddEditOrderExport.show = false;
  }

  editOrderExport(element: any) {
    this.dialogAddEditOrderExport.model = { ...organisationAllocationProperty.parse(element) };
    this.dialogAddEditOrderExport.show = true;
  }

  //#endregion
}
