import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import NumberUtils from '@/shared/utils/NumberUtils';
import organisationAllocationExportProperty, {
  OrganisationAllocationExportProperty,
} from '@/shared/model/organisationAllocationExportProperty';

const name = 'edit-order-export';
const logger = new Logger(name);
const allocationTemplateModule = namespace('allocationTemplate');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({
  name: 'edit-order-export',
})
export default class EditOrderExport extends Vue {
  @Ref('auto-gobd-edit-form')
  private refEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => organisationAllocationExportProperty.parse({}) })
  private value!: OrganisationAllocationExportProperty;

  @Watch('value')
  onUpdateValue(newV: OrganisationAllocationExportProperty, oldV: OrganisationAllocationExportProperty) {
    this.editedItem = Object.assign(this.editedItem, newV);
    this.resetValidation();
  }

  get btnName() {
    return this.editedItem.enabled ? this.$t('deactivate') : this.$t('activate');
  }

  private editedItem: OrganisationAllocationExportProperty = organisationAllocationExportProperty.parse({});

  private isFormValid = true;

  async created() {}

  resetValidation() {
    if (this.refEditForm) this.refEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdateEnabled() {
    if (this.editedItem.enabled) this.editedItem.enabled = false;
    else this.editedItem.enabled = true;
    this.onClickUpdate();
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.editedItem);
    }
  }
}
