import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import NumberUtils from '@/shared/utils/NumberUtils';
import organisationAllocationProperty, {
  OrganisationAllocationProperty,
} from '@/shared/model/organisationAllocationProperty';

const name = 'edit-order-booking';
const logger = new Logger(name);
const allocationTemplateModule = namespace('allocationTemplate');
const documentExtraStatusSettingModule = namespace('organisationDocumentExtraStatusSetting');

@Component({
  name: 'edit-order-booking',
})
export default class EditOrderBooking extends Vue {
  @Ref('auto-gobd-edit-form')
  private refEditForm!: any;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => organisationAllocationProperty.parse({}) })
  private value!: OrganisationAllocationProperty;

  @Watch('value')
  onUpdateValue(newV: OrganisationAllocationProperty, oldV: OrganisationAllocationProperty) {
    this.editedItem = Object.assign(this.editedItem, newV);
    this.resetValidation();
  }

  maske2 = [
    'Rechnungsnummer',
    'Steuer-Schlüssel',
    'Buchungstext',
    'Seriennummer',
    'Filiale',
    'Marke',
    'Kostenstelle',
    'Kostenträger',
    'Absatzkanal',
    'Skontosatz',
    'Währung',
    'Belegkreis',
    'Belegstext 1',
    'Belegstext 2',
    'Buchungsart',
    'Persornalnummer',
    'Herkunft',
    'Erw. Vertriebsweg',
    'Händler eigener Kostenträger',
    'Projekt',
    'Antriebsart',
  ];

  private isMaske2(name: string) {
    return this.maske2.includes(name);
  }

  get btnName() {
    return this.editedItem.enabled ? this.$t('deactivate') : this.$t('activate');
  }

  private editedItem: OrganisationAllocationProperty = organisationAllocationProperty.parse({});

  private isFormValid = true;

  get rules() {
    return {
      required: (value: any) => value.length > 0 || this.$t('messages.required'),
      mustbyint: (value: any) => NumberUtils.isNumeric(value) || this.$t('messages.must_be_int'),
    };
  }

  async created() {}

  resetValidation() {
    if (this.refEditForm) this.refEditForm.resetValidation();
  }

  onClickClose() {
    this.resetValidation();
    this.$emit('click:close');
  }

  async onClickUpdateEnabled() {
    if (this.editedItem.enabled) this.editedItem.enabled = false;
    else this.editedItem.enabled = true;
    this.onClickUpdate();
  }

  async onClickUpdate() {
    const isFormValidResult = await this.refEditForm.validate();

    if (isFormValidResult) {
      this.$emit('click:update', this.editedItem);
    }
  }
}
